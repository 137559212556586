import styles from './Projects.module.css'
import {forwardRef, useContext} from "react";
import Section from "../Section/Section";
import {GlobalContext, Translation} from "../../globals";
import webex from './webex/webex'


const Projects = forwardRef(function Projects(props, ref) {
    const {language} = useContext(GlobalContext)

    return (
        <Section heading={props.name.get(language)} ref={ref}>
            {webex.get(language)}
        </Section>
    )
})

export default Projects