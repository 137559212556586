import styles from './Toggler.module.css'
import PropTypes from "prop-types";

function Toggler(props) {
    const options = props.options

    const currentIdx = options.findIndex(o => o.value === props.value)

    const current = options[currentIdx]

    const padding = '3px 15px'
    const border = '2px'

    const color = current.color

    return (
        <div className={styles.wrapper}
             style={{
                 border: `${border} solid ${color}`
             }}
        >
            {
                options.map((option, i) => (
                    <div className={styles.option} key={i}
                         onClick={() => i !== currentIdx && props.onChange && props.onChange(options[i].value)}
                         style={{
                             borderWidth: i === 1 ? `0 ${border}` : null,
                             borderStyle: i === 1 ? 'solid' : null,
                             borderColor: i === 1 ? current.color : null,
                             padding
                         }}
                    >
                        {option.text}
                    </div>
                ))
            }

            <div className={styles.slider}
                 style={{
                     backgroundColor: color,
                     transform: `translateX(${currentIdx * 100}%)`,
                     width: `${100 / options.length}%`,
                     padding
                 }}
            >
                {current.text}
            </div>
        </div>
    )
}

export default Toggler