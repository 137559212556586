import styles from './Contact.module.css'
import {forwardRef, useContext} from "react";
import Section from "../Section/Section";
import {GlobalContext, Translation} from "../../globals";
import linkedinCode from './linkedin.png'
import wechatCode from './wechat.png'

const email = new Translation('Email:', 'Email:', '电邮')
const mobile = new Translation('Mobile:', 'Mobil:', '手机')

const linkedin = new Translation('LinkedIn:', 'LinkedIn:', '领英')
const wechat = new Translation('WeChat:', 'WeChat:', '微信')

const linkedinLink = 'https://www.linkedin.com/in/matej-porubovic'
const wechatLink = 'https://u.wechat.com/kMQDqvuqsP2KuQzAb_XOS5w'


const Contact = forwardRef(function Contact(props, ref) {
    const {language} = useContext(GlobalContext)

    return (
        <Section heading={props.name.get(language)} ref={ref}>
            <div className={styles.contact}>
                <div className={styles.cols}>
                    <div className={styles.col}>
                        <span>{email.get(language)}</span>
                        <a className='unstyled-link' href='mailto:matej@porubovic.sk'>
                            <strong>matej@porubovic.sk</strong>
                        </a>
                        <span>{mobile.get(language)}</span>
                        <a className='unstyled-link' href='tel:+447539466503'>
                            <strong>+44 7539 466503</strong>
                        </a>
                    </div>

                    <div className={styles.col}>
                        <span>{linkedin.get(language)}</span>
                        <a className='unstyled-link'
                           href={linkedinLink}
                           target='_blank'
                        >
                            <strong>Matej Porubovic</strong>
                        </a>
                        <span>{wechat.get(language)}</span>
                        <a className='unstyled-link'
                           href={wechatLink}
                           target='_blank'
                        >
                            <strong>mporubovic</strong>
                        </a>
                    </div>
                </div>

                <div className={styles.qrCodes}>
                    <a href={linkedinLink} target='_blank'>
                        <img alt="" src={linkedinCode}/>
                    </a>
                    <a href={wechatLink} target='_blank'>
                        <img alt="" src={wechatCode}/>
                    </a>
                </div>
            </div>
        </Section>
    )
})


export default Contact