import {createContext} from "react";

export const SECTION_TYPE = {
    COMPONENT: 0,
    LINK: 1
}

export const LANGUAGE = {
    EN: 'en',
    ZH: 'zh',
    SK: 'sk'
}

export const GlobalContext = createContext({
    language: LANGUAGE.EN
})

export class Translation {
    constructor(en, sk, zh) {
        this[LANGUAGE.EN] = en
        this[LANGUAGE.SK] = sk
        this[LANGUAGE.ZH] = zh
    }

    get(lang) {
        return this[lang]
    }
}