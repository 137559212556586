import styles from './Web.module.css';
import './global.css'
import Navigation from "./Navigation/Navigation";
import About from "./Sections/About/About";
import Projects from "./Sections/Projects/Projects";
import Contact from "./Sections/Contact/Contact";
import {cloneElement, createContext, createRef, useEffect, useRef, useState} from "react";
import {SECTION_TYPE, Translation, GlobalContext, LANGUAGE} from "./globals";

function Web() {

    const SECTIONS = {
        ABOUT: {
            name: new Translation('about', 'o mne', '关于本人'),
            type: SECTION_TYPE.COMPONENT,
            component: <About/>,
            ref: useRef()
        },
        PROJECTS: {
            name: new Translation('projects', 'projekty', '项目简介'),
            type: SECTION_TYPE.COMPONENT,
            component: <Projects/>,
            ref: useRef()
        },
        CONTACT: {
            name: new Translation('contact', 'kontakt', '联系方式'),
            type: SECTION_TYPE.COMPONENT,
            component: <Contact/>,
            ref: useRef()
        },
        // BLOG: { // later...
        //     name: new Translation('blog', 'blog', '博客'),
        //     type: SECTION_TYPE.LINK,
        //     href: 'https://blog.porubovic.sk/'
        // }
    }

    const [language, setLanguage] = useState(LANGUAGE.ZH)

    return (
        <div className={styles.web}>
            <GlobalContext.Provider
                value={{language}}
            >
                <Navigation sections={SECTIONS} setLanguage={setLanguage}/>

                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        {
                            Object.values(SECTIONS).map((s, i) =>
                                s.type === SECTION_TYPE.COMPONENT &&
                                cloneElement(s.component, {key: i, ref: s.ref, name: s.name})
                            )
                        }

                        <div className={styles.spacer}/>
                    </div>
                </div>
            </GlobalContext.Provider>
        </div>
    )
}

export default Web;
