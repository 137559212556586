import styles from './Navigation.module.css'
import Toggler from "./Toggler/Toggler";

import first from "lodash/first"
import {useContext} from "react";
import {GlobalContext, LANGUAGE, SECTION_TYPE, Translation} from "../globals";

export const LANGUAGE_TO_COLOR = {
    [LANGUAGE.EN]: '#545454',
    [LANGUAGE.SK]: '#44A5FF',
    [LANGUAGE.ZH]: '#A80000'
}


function Navigation(props) {
    const {language} = useContext(GlobalContext)

    const heading = new Translation('Matej Porubovic', 'Matej Porubovič', '马杰')

    const {sections: tabs, setLanguage} = props

    const firstTab = first(Object.values(tabs))

    const scrollToTab = (tab) => {
        if (tab.ref === firstTab.ref) window.scrollTo({top: 0, behavior: "smooth"})
        else tab.ref.current.scrollIntoView({behavior: "smooth", block: "start"})
    }

    return (
        <div className={styles.navigation}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    {heading.get(language)}
                </div>

                <div className={styles.sections}>
                    {
                        Object.values(tabs).map((t, i) =>
                            t.type === SECTION_TYPE.COMPONENT ?
                                (
                                    <div key={i}
                                         className={language === LANGUAGE.ZH ? styles.tabRed : styles.tab}
                                         onClick={() => scrollToTab(t)}
                                    >
                                        {t.name.get(language)}
                                    </div>
                                ) : t.type === SECTION_TYPE.LINK ?
                                    (
                                        <a key={i}
                                           className={language === LANGUAGE.ZH ? styles.tabRed : styles.tab}
                                           href={t.href}
                                           target='_blank'
                                        >
                                            {t.name.get(language)}
                                        </a>
                                    ) : null
                        )
                    }
                </div>

                <Toggler
                    options={[
                        {value: LANGUAGE.EN, text: 'en', color: LANGUAGE_TO_COLOR[LANGUAGE.EN]},
                        {value: LANGUAGE.SK, text: 'sk', color: LANGUAGE_TO_COLOR[LANGUAGE.SK]},
                        {value: LANGUAGE.ZH, text: 'cn', color: LANGUAGE_TO_COLOR[LANGUAGE.ZH]},
                    ]}
                    value={language}
                    onChange={setLanguage}
                />
            </div>
        </div>
    )
}

export default Navigation