import styles from './About.module.css'
import {forwardRef, useContext, useEffect, useState} from "react";
import Section from "../Section/Section";
import {GlobalContext, Translation} from "../../globals";
import {LANGUAGE_TO_COLOR} from "../../Navigation/Navigation";
import photo from './photo.jpg'

const translation = new Translation(
    (<>
        <p>Software developer with 3 years of experience in designing and developing custom-built software.</p>
        <p>Fluent in English, Mandarin Chinese & Slovak.</p>
        <p>Based in London.</p>
    </>),
    (<>
        <p>Softvérový developér s 3 ročnými skúsenosťami v oblasti dizajnu a vývoju softvérov na mieru.</p>
        <p>Plynulý v angličtine, slovenčine a čínštine.</p>
        <p>Zázemím v Londýne.</p>
    </>),
    (<>
        <p>专注量身定制软件的设计和开发，具有三年工作经验的软件开发者。</p>
        <p>流利掌握汉语、英语和斯洛伐克语。</p>
        <p>现居伦敦。</p>
    </>)
)

const buttons = (language) => (
    <div className={styles.buttons}>
        <a className={styles.button}
           href="https://porubovic.sk/cv.pdf"
           target='_blank'
           style={{backgroundColor: LANGUAGE_TO_COLOR[language]}}
        >CV</a>
        <a className={styles.button}
           href="https://www.linkedin.com/in/matej-porubovic"
           target='_blank'
           style={{backgroundColor: LANGUAGE_TO_COLOR[language]}}
        >LinkedIn</a>
        <a className={styles.button}
           href="https://github.com/mporubovic"
           target='_blank'
           style={{backgroundColor: LANGUAGE_TO_COLOR[language]}}
        >GitHub</a>
    </div>
)

const About = forwardRef(function About(props, ref) {
    const {language} = useContext(GlobalContext)

    const [responsiveBreakpoint, setResponsiveBreakpoint] = useState(false)

    const resize = (e) => {
        if (window.innerWidth < 700)
            setResponsiveBreakpoint(true)
        else
            setResponsiveBreakpoint(false)
    }

    useEffect(() => {
        window.addEventListener('resize', resize)

        resize()

        return () => window.removeEventListener('resize', resize)
    }, [])

    return (
        <Section heading={props.name.get(language)}
                 contentStyle={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "30px"}}
        >
            <div className={styles.row}>
                <img alt="" src={photo}/>

                <div className={styles.column}>

                    <div className={styles.text}>
                        {translation.get(language)}
                    </div>

                    {
                        !responsiveBreakpoint && buttons(language)
                    }
                </div>
            </div>

            {
                responsiveBreakpoint && buttons(language)
            }


        </Section>
    )
})

export default About