import {Translation} from "../../../globals";
import styles from "../Projects.module.css";
import analytika from './analytika.png'
import lpk from './lpk.png'
import zoznam from './zoznam.png'

const _images = [analytika, lpk, zoznam]

const images = (
    <div className={styles.images}>
        {
            _images.map((img, i) => (
                <img alt="" src={img} key={i}/>
            ))
        }
    </div>
)

const translation = new Translation(
    (<>
        <p><strong>Real Estate CRM</strong> — ongoing development</p>
        <p>Leading a team of 3 software developers, I designed, developed and implemented a custom-made CRM
            (customer relationship management) software for a real estate agency.</p>
        <br/>
        <div className={styles.grid}>
            <span>Use case:</span>
            <span>Managing leads and client–broker communication, asset database management</span>

            <span>Link:</span>
            <a className='link' target='_blank' href='https://www.expat.sk/en'>www.expat.sk/en</a>

            <span>Technologies:</span>
            <div className={styles.col}>
                <span>React, Node, Laravel (PHP), MySQL, WordPress</span>
                <span>Google Cloud Platform, GitLab pipelines</span>
                <span>Figma, IcePanel</span>
            </div>

            <span>Responsibilities:</span>
            <div className={styles.col}>
                <span>Project management</span>
                <span>System architecture</span>
                <span>Frontend & backend development</span>
                <span>Database design</span>
                <span>UI design</span>
                <span>Documentation</span>
            </div>

            <span>Key features:</span>
            <div className={styles.col}>
                <span>Analytics dashboard</span>
                <span>Custom UI component library</span>
                <span>Secure, password-less login using WebAuthn</span>
                <span>Integration with external APIs</span>
                <span>Continuous backup and monitoring</span>
            </div>
        </div>

        <br/>
    </>),
    (<>
        <p><strong>Realitný CRM systém</strong> — dlhodobý vývoj</p>
        <p>Spolu s 3 developérmi pod mojím vedením som nadizajnoval, naprogramoval a implementoval realitný CRM
            (customer relationship management) systém na mieru
            pre realitnú kanceláriu v Bratislave.</p>
        <br/>

        <div className={styles.grid}>
            <span>Použitie:</span>
            <span>Správa dopytov a komunikácie medzi maklérmi a klientmi, správa databázy nehnuteľností</span>

            <span>Link:</span>
            <a className='link' target='_blank' href='https://www.expat.sk'>www.expat.sk</a>

            <span>Technológie:</span>
            <div className={styles.col}>
                <span>React, Node, Laravel (PHP), MySQL, WordPress</span>
                <span>Google Cloud Platform, GitLab pipelines</span>
                <span>Figma, IcePanel</span>
            </div>

            <span>Zodpovednosti:</span>
            <div className={styles.col}>
                <span>Project management</span>
                <span>System architecture</span>
                <span>Frontend & backend development</span>
                <span>Database design</span>
                <span>UI design</span>
                <span>Documentation</span>
            </div>

            <span>Hlavné funkcie:</span>
            <div className={styles.col}>
                <span>Analytics dashboard</span>
                <span>Custom UI component library</span>
                <span>Secure, password-less login using WebAuthn</span>
                <span>Integration with external APIs</span>
                <span>Continuous backup and monitoring</span>
            </div>
        </div>

        <br/>
    </>),
    (<>
        <p><strong>房地产CRM系统</strong> — 长期开发</p>
        <p>带领三名队内开发者为一家斯洛伐克房地产中介设计、开发与实现房地产CRM（客户关系管理）量身定制系统。</p>
        <br/>

        <div className={styles.grid}>
            <span className={styles.label}>使用案例</span>
            <span>潜在客户与房地产经纪人交流、资产管理</span>

            <span className={styles.label}>网站链接</span>
            <a className='red-link' target='_blank' href='https://www.expat.sk/en'>www.expat.sk/en</a>

            <span className={styles.label}>技术</span>
            <div className={styles.col}>
                <span>React, Node, Laravel (PHP), MySQL, WordPress</span>
                <span>Google Cloud Platform, GitLab pipelines</span>
                <span>Figma, IcePanel</span>
            </div>

            <span className={styles.label}>工作内容</span>
            <div className={styles.col}>
                <span>项目管理</span>
                <span>系统架构</span>
                <span>前段与后段开发</span>
                <span>数据库设计</span>
                <span>UI设计</span>
                <span>软件文档编制</span>
            </div>

            <span className={styles.label}>关键功能</span>
            <div className={styles.col}>
                <span>信息中心</span>
                <span>量身定制的UI组件库</span>
                <span>安全、无需密码WebAuthn登陆</span>
                <span>外在API融入</span>
                <span>连续备份与监测</span>
            </div>
        </div>

        <br/>
    </>)
)


export default translation