import styles from './Section.module.css'
import {forwardRef} from "react";

const Section = forwardRef(function Section(props, ref) {
    const {heading, children, contentStyle} = props

    return (
        <div className={styles.section}>
            <div className={styles.heading}>
                <span ref={ref}>{heading}</span>
                <div className={styles.line}/>
            </div>


            <div className={styles.content} style={contentStyle}>
                {children}
            </div>
        </div>
    )
})

export default Section